/*CSS overrides for react-toastify*/

:root {
	--toastify-color-success: #00ef91 !important; /*theme.kozmikGreen*/
	--toastify-color-error: #ff2619 !important; /*theme.red*/
}

.Toastify__toast-container {
	--toastify-toast-min-height: 44px;
}

.Toastify__toast-body {
	font-size: 14px;
}
