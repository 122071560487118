/* :root {
	scrollbar-color: rgb(210, 210, 210) rgb(46, 54, 69) !important;
	scrollbar-width: thin !important;
} */
/* width */
::-webkit-scrollbar {
	width: 7px;
	height: 7px;
}
/* Track */
::-webkit-scrollbar-track {
	background: rgb(255, 255, 255);
	box-shadow: inset 0 0 0;
	border-radius: 7px;
}
/* Handle */
::-webkit-scrollbar-thumb {
	background: rgb(228, 228, 228);
	border-radius: 7px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: rgb(221, 221, 221);
}
