@import url('./styles/toast.css');
@import url('./styles/scrollbar.css');

* {
	text-decoration: none;
	box-sizing: border-box;
}

@font-face {
	font-family: 'Roboto';
	src: url(./assets/fonts/RobotoCondensed-Regular.ttf);
}

body {
	margin: 0;
	font-family: 'Open Sans', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}
